import { BaseEntity } from './shared/base-entity';

export enum StandardRole {
  Admin = 'admin',
  Patient = 'patient',
  Integration = 'integration',
}

export enum Action {
  Create = 'create',
  List = 'list',
  Read = 'read',
  Update = 'update',
  Delete = 'delete',
}

export enum ProtectedResource {
  User = 'user',
  CaseReport = 'caseReport',
  TypeformResponse = 'typeformResponse',
  MediaItem = 'mediaItem',
  AccessToken = 'accessToken',
  AnonymizedCaseReport = 'anonymizedCaseReport',
  Stats = 'stats',
  Role = 'role',
  CommerceOrder = 'commerceOrder',
  Emr = 'emr',
  Webdoc = 'webdoc',
  AuditLog = 'auditLog',
  Ticket = 'ticket',
  ImpersonationToken = 'impersonationToken',
  TextTemplate = 'textTemplate',
  Order = 'order',
}

export interface Role extends BaseEntity {
  /**
   * doctor, patient, admin, etc ...
   */
  role: string;
  /**
   * Array of strings which will have the following format
   * `${resource}/${action}`
   */
  permissions: string[];
}
