import React from 'react';
import { useRouter } from 'next/router';
import {
  Col,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import 'react-image-lightbox/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import { connect, ConnectedProps } from 'react-redux';
import { Settings } from 'react-feather';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { io, Socket } from 'socket.io-client';
import Sidebar from '../components/sidebar/sidebar.component';
import { RootState } from '../model/slices/root/root.state';
import { actions } from '../model/slices/app/app.slice';
import useMeQuery from '../model/hooks/use-me-query';
import LocaleSelectBlock, {
  LocaleSelectBlockViewVariants,
} from '../components/locale-select-block/locale-select-block';
import { clientConfig } from '../model/config';
import SocketContext from '../components/socket-context/socket-context';
import setReactDatepickerLocale from '../utils/set-react-datepicker-locale/set-react-datepicker-locale';
import FontScaleControl from '../components/font-scale-control/font-scale-control.component';
import PageMeta from '../components/page-meta/page-meta.component';
import Loader from '../components/loader/loader.component';

const mapStateToProps = (state: RootState) => ({
  isBoxed: state.app.isBoxed,
  sidebarIsOpen: state.app.sidebarIsOpen,
  loaderOverlayVisible: state.app.loaderOverlayVisible,
});

const mapDispatchToProps = {
  toggleSidebarIsOpen: actions.toggleSidebarIsOpen,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type DashboardProps = { children: React.ReactNode } & ConnectedProps<typeof connector>;

const Dashboard: React.FC<DashboardProps> = ({
  children,
  isBoxed,
  toggleSidebarIsOpen,
  loaderOverlayVisible,
}) => {
  const { t, i18n } = useTranslation();

  const router = useRouter();

  const [socket, setSocket] = React.useState<Socket | null>(null);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const newSocket = io(clientConfig.apiUrl, {
        withCredentials: true,
      });
      setSocket(newSocket);
      return () => {
        newSocket.close();
      };
    }
    return () => {};
  }, []);

  React.useEffect(() => {
    setReactDatepickerLocale(i18n.resolvedLanguage);
  }, [i18n.resolvedLanguage]);

  const { data: user } = useMeQuery();

  return (
    <SocketContext.Provider value={socket}>
      <div className={`wrapper ${isBoxed ? 'wrapper-boxed' : ''}`}>
        <PageMeta title={t('DASHBOARD_LAYOUT.META.TITLE')} />
        <Sidebar pathName={router.pathname} />
        <div className="main">
          <Navbar color="white" light expand>
            <span className="sidebar-toggle d-flex ms-4" onClick={() => toggleSidebarIsOpen()}>
              <i className="hamburger align-self-center" />
            </span>
            <Collapse navbar>
              <Nav className="navbar-align align-items-center" navbar>
                <FontScaleControl />
                <LocaleSelectBlock
                  className="locale__container--light-mode d-none d-sm-inline-block me-2"
                  viewVariant={LocaleSelectBlockViewVariants.Switcher}
                />
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle
                    nav
                    caret
                    className="position-absolute d-sm-none"
                    style={{ marginLeft: '-30px' }}
                  >
                    <Settings size={18} className="align-middle" />
                  </DropdownToggle>
                  <DropdownToggle nav className="d-flex align-items-center">
                    {user && <span className="text-dark me-3">{user.fullName}</span>}
                    {user && (
                      <div
                        style={{
                          backgroundImage: user.profilePictureUrl
                            ? `url(${user.profilePictureUrl})`
                            : 'url(/img/user-avatar-placeholder.svg)',
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          display: 'inline-block',
                        }}
                        className="avatar img-fluid rounded-circle me-3"
                      />
                    )}
                  </DropdownToggle>
                  <DropdownMenu right>
                    <Link href="/my-account" passHref>
                      <DropdownItem>{t('NAVBAR.MY_ACCOUNT')}</DropdownItem>
                    </Link>
                    <Link href="/logout" passHref>
                      <DropdownItem>{t('NAVBAR.LOGOUT')}</DropdownItem>
                    </Link>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </Navbar>
          <div className="content">{children}</div>
          {loaderOverlayVisible && (
            <div className="page-overlay" style={{ opacity: 0.8 }}>
              <Loader />
            </div>
          )}
          <footer className="footer">
            <Container fluid>
              <Row className="text-muted">
                <Col xs="12" className="text-end">
                  <p className="mb-0">
                    &copy; {new Date().getFullYear()} - <span className="text-muted">Ayd</span>
                  </p>
                </Col>
              </Row>
            </Container>
          </footer>
          <div className="react-table-portal" style={{ zIndex: 9999 }} />
        </div>
      </div>
    </SocketContext.Provider>
  );
};

export default connector(Dashboard);
