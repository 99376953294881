import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import { RootState } from '../../model/slices/root/root.state';
import { actions } from '../../model/slices/app/app.slice';
import { MAX_FONT_SCALE, MIN_FONT_SCALE } from '../../model/constants';

const FONT_SCALE_CHANGE_STEP = 5;

const FontScaleControl = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const fontScale = useSelector((state: RootState) => state.app.fontScale);

  const setFontScale = (newFontScale: number) => {
    const validScale = Math.max(Math.min(newFontScale, MAX_FONT_SCALE), MIN_FONT_SCALE);
    dispatch(actions.setFontScale(validScale));
  };

  return (
    <div className="font-scale-control">
      <style jsx global>{`
        :root {
          --ayd-font-scale: ${fontScale};
        }
      `}</style>
      <span
        onClick={() => setFontScale(fontScale - FONT_SCALE_CHANGE_STEP)}
        className="font-scale-control__button"
      >
        -
      </span>
      <span className="font-scale-control__label">
        {t('FONT_SCALE_CONTROL.LABEL', { value: fontScale })}
      </span>
      <span
        onClick={() => setFontScale(fontScale + FONT_SCALE_CHANGE_STEP)}
        className="font-scale-control__button"
      >
        +
      </span>
    </div>
  );
};

export default FontScaleControl;
