import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import useUpdateMeMutation from '../../model/hooks/use-update-me-mutation';
import useMeQuery from '../../model/hooks/use-me-query';
import getLocalizedPath from '../../utils/get-localized-path/get-localized-path';

export enum LocaleSelectBlockViewVariants {
  Buttons,
  Switcher,
}

type LocaleSelectBlockProps = {
  className?: string;
  viewVariant?: LocaleSelectBlockViewVariants;
  style?: any;
};

const LocaleSelectBlock: React.FC<LocaleSelectBlockProps> = ({
  className,
  viewVariant = LocaleSelectBlockViewVariants.Buttons,
  style,
}) => {
  const router = useRouter();
  const { asPath, defaultLocale } = router;
  const { i18n } = useTranslation();
  const ref = React.useRef(null);

  const { data: user } = useMeQuery();
  const { mutateAsync: saveUser } = useUpdateMeMutation({
    // Prevent the language change from being applied immediately as it causes a page
    // reload before the request has a chance to update the language on the server
    optimisticUpdatesEnabled: false,
  });

  const onClick = (loc: string) => {
    const newPath = getLocalizedPath({ path: asPath, defaultLocale, locale: loc });
    if (user) {
      saveUser({
        preferences: {
          ...user.preferences,
          language: loc,
        },
      })
        .then(() => window.location.replace(newPath))
        .catch(() => {});
    } else {
      window.location.replace(newPath);
    }
  };

  return (
    <div className={`locale__container ${className}`} style={style} ref={ref}>
      {viewVariant === LocaleSelectBlockViewVariants.Switcher && (
        <div
          className="locale__text-variant"
          onClick={() => onClick(i18n.resolvedLanguage === 'en' ? 'sv' : 'en')}
        >
          {i18n.resolvedLanguage === 'en' ? 'SE' : 'EN'}
        </div>
      )}
      {viewVariant === LocaleSelectBlockViewVariants.Buttons && (
        <div className="locale__buttons-variant">
          <span
            className={`btn ${i18n.resolvedLanguage === 'sv' ? '' : 'btn-border'}`}
            style={{ marginRight: 10 }}
            onClick={() => onClick('sv')}
          >
            Svenska
          </span>
          <span
            className={`btn ${i18n.resolvedLanguage === 'en' ? '' : 'btn-border'}`}
            onClick={() => onClick('en')}
          >
            English
          </span>
        </div>
      )}
    </div>
  );
};

export default LocaleSelectBlock;
