import { User } from '../../model/entities/user.entity';
import {
  getPermissionsFromConfig,
  PermissionConfig,
} from '../get-permissions-from-config/get-permissions-from-config';

const checkUserPermissions = (
  config: PermissionConfig | PermissionConfig[],
  user: User
): boolean => {
  const requiredPermissions = getPermissionsFromConfig(config);
  return requiredPermissions.every(permission => user.permissions.includes(permission));
};

export default checkUserPermissions;
