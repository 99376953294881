import { Action, ProtectedResource } from '../../model/entities/role.entity';
import buildPermission from '../build-permission/build-permission';

export type PermissionConfig = [ProtectedResource, Action];

export const getPermissionsFromConfig = (
  config: PermissionConfig[] | PermissionConfig
): string[] => {
  if (config.length === 0) {
    return [];
  }
  const configArray = (typeof config[0] === 'string' ? [config] : config) as PermissionConfig[];
  return configArray.map(([resource, action]) => buildPermission(resource, action));
};

export default getPermissionsFromConfig;
