import React from 'react';
import { PermissionConfig } from '../../utils/get-permissions-from-config/get-permissions-from-config';
import useCheckAccess from '../../hooks/use-check-access/use-check-access';

type GateProps = {
  config: PermissionConfig[] | PermissionConfig;
};

const Gate: React.FC<GateProps> = ({ config, children }) => {
  const checkAccess = useCheckAccess();
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return checkAccess(config) ? <>{children}</> : null;
};

export default Gate;
