import { registerLocale, setDefaultLocale } from 'react-datepicker';
import sv from 'date-fns/locale/sv';
import enGB from 'date-fns/locale/en-GB';

registerLocale('sv', sv);
registerLocale('en', enGB);

const setReactDatepickerLocale = (locale: string) => {
  setDefaultLocale(locale);
};

export default setReactDatepickerLocale;
