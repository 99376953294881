import { Badge, Collapse } from 'reactstrap';
import React from 'react';
import { Icon as FeatherIcon } from 'react-feather';

type SidebarCategoryProps = {
  name: string;
  badgeColor?: string;
  badgeText?: string;
  icon: FeatherIcon;
  isOpen?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
  pathName?: string;
  to?: string;
};

const SidebarCategory: React.FC<SidebarCategoryProps> = ({
  name,
  badgeColor,
  badgeText,
  icon: Icon,
  isOpen,
  children,
  onClick,
  pathName,
  to,
}) => {
  const getSidebarItemClass = (path: string | undefined) => {
    return (path != null && pathName != null && pathName.indexOf(path) !== -1) ||
      (pathName === '/' && path === '/dashboard')
      ? 'active'
      : '';
  };

  return (
    <li className={`sidebar-item ${getSidebarItemClass(to)}`}>
      <span
        data-toggle="collapse"
        className={`sidebar-link ${!isOpen ? 'collapsed' : ''}`}
        onClick={onClick}
        aria-expanded={isOpen ? 'true' : 'false'}
      >
        <Icon size={18} className="align-middle me-3" />
        <span className="align-middle">{name}</span>
        {badgeColor && badgeText ? (
          <Badge color={badgeColor} size={18} className="sidebar-badge">
            {badgeText}
          </Badge>
        ) : null}
      </span>
      <Collapse isOpen={isOpen}>
        <ul id="item" className="sidebar-dropdown list-unstyled">
          {children}
        </ul>
      </Collapse>
    </li>
  );
};

export default SidebarCategory;
