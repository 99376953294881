import Head from 'next/head';
import React from 'react';

type PageMetaProps = {
  title: string;
  description?: string;
  keywords?: string;
  imageUrl?: string;
};

const PageMeta: React.FC<PageMetaProps> = ({ title, description, keywords, imageUrl }) => {
  return (
    <Head>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      {description && (
        <>
          <meta property="og:description" content={description} />
          <meta name="description" content={description} />
        </>
      )}
      {keywords && (
        <>
          <meta property="og:keywords" content={keywords} />
          <meta name="keywords" content={keywords} />
        </>
      )}
      {imageUrl && <meta property="og:image" content={imageUrl} />}
    </Head>
  );
};

export default PageMeta;
