import * as React from 'react';
import { NextRouter, useRouter } from 'next/router';
import { NextComponentType } from 'next';
import { BaseContext, NextPageContext } from 'next/dist/shared/lib/utils';
import { User } from '../../model/entities/user.entity';
import useMeQuery from '../../model/hooks/use-me-query';
import { PermissionConfig } from '../get-permissions-from-config/get-permissions-from-config';
import checkUserPermissions from '../check-user-permissions/check-user-permissions';
import Loader from '../../components/loader/loader.component';

export type WithAuthProps = {
  user: User;
};

function withAuth<C extends BaseContext = NextPageContext, IP = {}, P = {}>(
  Component: NextComponentType<C, IP, P & WithAuthProps>,
  config: PermissionConfig[] | PermissionConfig,
  redirectUrlCallback?: (origin: string, router: NextRouter) => string
) {
  const Auth = (props: P) => {
    const router = useRouter();
    const [rolesChecked, setRolesChecked] = React.useState(false);
    const { data: user = null, isLoading } = useMeQuery();

    // Check roles
    React.useEffect(() => {
      if (!isLoading && router.isReady) {
        if (user) {
          // The user was fetched => check the permissions
          if (!checkUserPermissions(config, user) || user.permissions.length === 0) {
            router.replace('/access-denied').catch(() => {});
          } else {
            setRolesChecked(true);
          }
        } else {
          // The user is missing on another page => show the sign-in page
          router
            .replace(
              redirectUrlCallback
                ? redirectUrlCallback(document.location.origin, router)
                : `/sign-in?returnPath=${encodeURIComponent(router.asPath)}`
            )
            .catch(() => {});
        }
      }
    }, [isLoading, router, user]);

    // Show loading block if query is pending or roles is checking
    if (user == null || isLoading || !rolesChecked) {
      return (
        <div className="page-overlay">
          <Loader />
        </div>
      );
    }

    return <Component {...props} user={user} />;
  };

  // Copy getInitial props so it will run as well
  if (Component.getInitialProps) {
    Auth.getInitialProps = Component.getInitialProps;
  }

  // @ts-ignore
  if (Component.getLayout) {
    // @ts-ignore
    Auth.getLayout = Component.getLayout;
  }

  return Auth;
}

export default withAuth;
