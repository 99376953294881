import React from 'react';
import useMeQuery from '../../model/hooks/use-me-query';
import { PermissionConfig } from '../../utils/get-permissions-from-config/get-permissions-from-config';
import checkUserPermissions from '../../utils/check-user-permissions/check-user-permissions';

const useCheckAccess = () => {
  const { data: me } = useMeQuery();

  return React.useCallback(
    (config: PermissionConfig | PermissionConfig[]) =>
      me != null && checkUserPermissions(config, me),
    [me]
  );
};

export default useCheckAccess;
